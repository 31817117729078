import { db } from "@/firebase"

import {
  Denormalized,
  FirebaseService,
  Maybe
} from "@/services/firebase.service"
import { Theme } from "../types"

class ThemeService extends FirebaseService {
  getTheme(orgID: string, themeID: string): Promise<Maybe<Theme>> {
    return db
      .auxiliary()
      .ref(`orgs/${orgID}/themes/${themeID}`)
      .once("value")
      .then(snapshot => {
        const value: Maybe<Denormalized<Theme>> = snapshot.val()
        return value ? this.normalizeObjectLikeSnapshot(value, themeID) : null
      })
  }

  getNewThemeID(orgID: string) {
    return db.ref(`orgs/${orgID}/themes`).push().key
  }

  updateOrgTheme(
    orgID: string,
    themeID: string,
    value: Theme
  ): Promise<Maybe<Theme>> {
    return db
      .ref(`orgs/${orgID}/themes/${themeID}`)
      .set(this.deleteIdentity(value))
      .then(() => this.getTheme(orgID, themeID))
  }

  // TODO: Handle if theme is active
  deleteOrgTheme(orgID: string, themeID: string) {
    return db.ref(`orgs/${orgID}/themes/${themeID}`).set(null)
  }

  setOrgTheme(orgID: string, themeID: string) {
    return db.ref(`orgs/${orgID}/theme`).set(themeID)
  }

  async purgeGames(orgID: string, clientID: string) {
    const ref = db.auxiliary().ref(`org/${orgID}/games`)

    const snapshot = await ref
      .orderByChild("clientID")
      .equalTo(clientID)
      .once("value")

    const value = snapshot.val()

    /** @type {Record<string, null>} */
    const update = {}

    for (const gameID in value) {
      update[`${gameID}/themeID`] = null
      update[`${gameID}/styles`] = null
    }

    return ref.update(update)
  }
}

export default ThemeService
